import { __decorate } from "tslib";
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
let KlookOtpValidate = class KlookOtpValidate extends Vue {
    constructor() {
        super(...arguments);
        this.is_resend = 2; // 是否重发验证码 2-否 1-是
        this.isLoadingCode = false; // 是否正在加载验证码
        this.varifyIntervalSeconds = 60; // 验证码倒计时秒数
        this.errorText = ''; // 错误信息提示
        this.timer = null; // 倒计时定时器
        this.sendVerifyCodeInfo = {};
    }
    onSendTypeChange(val) {
        this.resetOtp();
    }
    onSendAccountChange(val) {
        this.resetOtp();
    }
    onPayloadChange(val) {
        this.resetOtp();
    }
    resetOtp() {
        this.isLoadingCode = false;
        clearInterval(this.timer);
        this.timer = null;
        this.varifyIntervalSeconds = 60;
        this.is_resend = 2;
        this.errorText = '';
        this.sendVerifyCodeInfo = {};
    }
    get isVarifyingCode() {
        return this.varifyIntervalSeconds < 60;
    }
    get inputDisabled() {
        return this.disabled || this.isVarifyingCode;
    }
    get remainingTips() {
        const second = this.varifyIntervalSeconds;
        return this.$t('signup.send_again', { second });
    }
    // 倒计时
    countingDown() {
        if (this.varifyIntervalSeconds > 0) {
            this.timer = setTimeout(() => {
                this.varifyIntervalSeconds--;
                this.countingDown();
            }, 1000);
        }
        else {
            this.varifyIntervalSeconds = 60;
        }
    }
    sendOtp() {
        // 发送验证码逻辑
        this.isLoadingCode = true;
        this.$ajax
            .post(this.sendOtpApiUrl ? this.sendOtpApiUrl : URLS.sendOtp, this.sendOtpApiParams ? this.sendOtpApiParams : {
            action: this.action,
            send_type: this.send_type,
            send_account: this.send_account,
            payload: this.payload,
            is_resend: this.is_resend,
            geetest_config: {
                needCaptcha: true
            }
        }, false, true)
            .then((res) => {
            this.sendVerifyCodeInfo = res;
            // next_send_timestamp 下次发送验证码时间戳
            if (res.next_send_timestamp) {
                this.varifyIntervalSeconds = Math.floor(res.next_send_timestamp - new Date().getTime() / 1000);
            }
            if (this.varifyIntervalSeconds >= 59) {
                this.$message.success(this.$t('31102'));
            }
            this.is_resend = 1;
            this.errorText = '';
            this.$emit('sendOtpSuccess', res);
            this.countingDown();
        })
            .catch((err) => {
            if (err.code === '81920') {
                this.$warning({
                    title: this.$t('200769'),
                    content: this.$t('200770'),
                    okText: this.$t('200771'),
                });
                return;
            }
            // 返回完整错误信息，用于展示
            this.errorText = err.message;
            this.$emit('sendOtpFail', err);
        })
            .finally(() => {
            this.isLoadingCode = false;
        });
    }
    verifyOtp() {
        return new Promise((resolve, reject) => {
            this.$ajax
                .post(URLS.verifyOtp, {
                otp_token: this.sendVerifyCodeInfo.otp_token,
                otp_code: this.inputValue,
            }, false, true)
                .then((res) => {
                this.$emit('verifyOtpSuccess', res);
                resolve(res);
            })
                .catch((err) => {
                if (err.code === '81920') {
                    this.$warning({
                        title: this.$t('200769'),
                        content: this.$t('200770'),
                        okText: this.$t('200771'),
                    });
                    return;
                }
                this.errorText = err.message;
                this.$emit('verifyOtpFail', err);
                reject(err);
            });
        });
    }
    handleBlur() {
        this.$emit('otpInputBlur');
    }
    handleInputChange() {
        this.$emit('otpInputChange');
        this.cleanError();
    }
    cleanError() {
        this.errorText = '';
    }
};
__decorate([
    Prop(String)
], KlookOtpValidate.prototype, "placeholder", void 0);
__decorate([
    Prop(String)
], KlookOtpValidate.prototype, "sendCodeText", void 0);
__decorate([
    Prop(String)
], KlookOtpValidate.prototype, "action", void 0);
__decorate([
    Prop(Boolean)
], KlookOtpValidate.prototype, "disabled", void 0);
__decorate([
    Prop(String)
], KlookOtpValidate.prototype, "send_type", void 0);
__decorate([
    Prop(String)
], KlookOtpValidate.prototype, "send_account", void 0);
__decorate([
    Prop(Object)
], KlookOtpValidate.prototype, "payload", void 0);
__decorate([
    Prop(Boolean)
], KlookOtpValidate.prototype, "showApiErrorMsg", void 0);
__decorate([
    Prop(String)
], KlookOtpValidate.prototype, "sendOtpApiUrl", void 0);
__decorate([
    Prop(Object)
], KlookOtpValidate.prototype, "sendOtpApiParams", void 0);
__decorate([
    PropSync('value', { type: String, default: '' })
], KlookOtpValidate.prototype, "inputValue", void 0);
__decorate([
    Watch('send_type', { immediate: false })
], KlookOtpValidate.prototype, "onSendTypeChange", null);
__decorate([
    Watch('send_account', { immediate: false })
], KlookOtpValidate.prototype, "onSendAccountChange", null);
__decorate([
    Watch('payload', { immediate: false, deep: true })
], KlookOtpValidate.prototype, "onPayloadChange", null);
KlookOtpValidate = __decorate([
    Component
], KlookOtpValidate);
export default KlookOtpValidate;
