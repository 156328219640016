import Logquery from '@klook/logquery';
const defaultOptions = {
    url: process && process.env && process.env.CLIENT_LOGQUERY_URL3 || 'https://log.fws.klooktest.io/v3/frontlogsrv/log/web',
    headers: { 'X-Platform': 'desktop' },
    queue: {
        interval: 5000,
        size: 15,
    },
};
const logquery = new Logquery(defaultOptions);
class Logger {
    /**
     * @param {*} error
     */
    constructor() {
        // content
    }
    handleGeneralInfo(info) {
        logquery.general({
            timestamp: Date.now(),
            isMasked: true,
            level: info.logquery_level || 'I',
            message: {
                subtype: info.sub_type || 'merchant:fe:merchant_counter_web',
                ...info,
                userAgent: window.navigator && window.navigator.userAgent || '',
                url: window.location.href,
                name: 'MERCHANT_COUNTER_WEB.Info',
            },
        });
    }
}
export default new Logger();
