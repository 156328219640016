export default {
    /***********************************************
    /* 全局公共接口 */
    getPhoneCode: '/v1/merchantcountersrv/common/phone_code_service/get_phone_code',
    /***********************************************
    * 用户接口 */
    login: '/v1/merchantcountersrv/user/login_service/login',
    logout: '/v1/merchantcountersrv/user/login_service/logout',
    getUserInfo: '/v1/merchantcountersrv/user/login_service/get_user_info',
    verifyToken: '/v1/merchantcountersrv/user/password_service/verify_reset_password_token',
    resetPassword: '/v1/merchantcountersrv/user/password_service/reset_password',
    changePassword: '/v1/merchantcountersrv/user/password_service/change_password',
    findPassword: '/v1/merchantcountersrv/user/password_service/find_password',
    sendBindVerification: '/v2/merchantcountersrv/user_manage_service/send_verify_code',
    loginWithPhoneCode: '/v1/merchantcountersrv/user/login_service/login_mobile_smscode',
    sendModifyPwdVerifyCodeByLoginType: '/v2/merchantcountersrv/password_service/send_code',
    verifyCode: '/v1/merchantcountersrv/user/password_service/verify_code',
    /* otp接入风控改造的接口 */
    // 发送otp接口
    sendOtp: '/v1/merchantcountersrv/otp_service/send_otp',
    // 校验otp接口
    verifyOtp: '/v1/merchantcountersrv/otp/otp_service/verify_otp',
    // 忘记密码的业务接口
    sendForgetPasswordOtp: '/v1/merchantcountersrv/otp/otp_service/find_pwd_by_login_id',
    // 手机号登录的业务接口
    sendPhoneLoginOtp: '/v1/merchantcountersrv/otp/otp_service/login',
    /***********************************************
    /* 首页接口 */
    getVoucherInfo: '/v1/merchantcountersrv/voucher/voucher_service/get_voucher_info',
    redeemVoucher: '/v1/merchantcountersrv/voucher/voucher_service/redeem_voucher',
    /***********************************************
    /* 兑换列表页接口 */
    getRedeemStatistics: '/v1/merchantcountersrv/redeem/redeem_service/redeem_statistics',
    getRedeemList: '/v1/merchantcountersrv/redeem/redeem_service/redeem_list',
    exportRedeemList: '/v1/merchantcountersrv/redeem/export',
    /***********************************************
    /* 账号管理页接口 */
    createUser: '/v1/merchantcountersrv/user/user_manage_service/create_user',
    getUserList: '/v1/merchantcountersrv/user/user_manage_service/get_user_list',
    unlockUser: '/v1/merchantcountersrv/user/user_manage_service/unlock_user',
    lockUser: '/v1/merchantcountersrv/user/user_manage_service/lock_user',
    deleteUser: '/v1/merchantcountersrv/user/user_manage_service/delete_user',
    resendActiveMessage: '/v1/merchantcountersrv/user/user_manage_service/resend_active_message',
    resetPasswordByGuid: '/v1/merchantcountersrv/user/password_service/set_password_by_user_guid',
    getPasswordUpdateLog: '/v1/merchantcountersrv/user/password_service/get_password_update_log',
};
